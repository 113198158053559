<template>
  <default-layout>
    <router-view v-if="!hasDefaultSlot"></router-view>
    <slot></slot>
  </default-layout>
</template>

<script>
import DefaultLayout from '../layouts/DefaultLayout.vue'

export default {
  components: {
    DefaultLayout
  },
  computed: {
    hasDefaultSlot() {
      return !!this.$slots.default
    }
  }
}
</script>
