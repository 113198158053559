import Vue from 'vue'
import Router from 'vue-router'
import Login from '../../Login.vue'
import Dashboard from '../Dashboard.vue'
import UserProfile from '../UserProfile.vue'
import UserRegister from '../UserRegister'

Vue.use(Router)

export default new Router({
  saveScrollPosition: true,
  history: true,
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Dashboard
    },
    {
      path: '/user/profile',
      name: 'user_profile',
      component: UserProfile
    },
    {
      path: '/user/register',
      name: 'user_register',
      component: UserRegister
    },
    {
      path: '/security/login',
      name: 'login',
      component: Login
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})
