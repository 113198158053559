<template>
  <div class="soupe-ui-layout-container">
    <div v-if="loading && !hasMessageBox" class="loading">
      <img width="60" height="60" style="margin: 0 auto;"
        src="@/assets/loading.gif"/>
    </div>
    <div class="position-fixed-top soupe-ui-message-box"></div>
    <page-header></page-header>
    <div class="soupe-ui-layout-body">
      <slot></slot>
    </div>
    <page-footer></page-footer>
  </div>
</template>

<script>
import PageHeader from './PageHeader'
import PageFooter from './PageFooter'

export default {
  components: {
    PageFooter,
    PageHeader
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    hasMessageBox() {
      return document.querySelector('.soupe-ui-message-background') !== null
    }
  },
  created() {
    if (this.$cookie.get('locale')) {
      this.lang = this.$cookie.get('locale')
      this.$i18n.locale = this.$cookie.get('locale').split('_')[0]
    }

    this.registerCheckingAuthenticationListener()
    this.registerLoadingEventListener()
  },
  mounted() {
    let self = this

    if (self.$el.querySelector('.main-content-wrapper')) {
      self.$el.querySelector('.main-content-wrapper').style.height =
        (window.innerHeight - self.$el.querySelector('.header-wrapper').clientHeight) + 'px'
    }

    window.addEventListener('resize', function() {
      if (self.$el.querySelector('.main-content-wrapper')) {
        self.$el.querySelector('.main-content-wrapper').style.height =
          (window.innerHeight - self.$el.querySelector('.header-wrapper').clientHeight) + 'px'
      }
    })
  },
  methods: {
    registerCheckingAuthenticationListener() {
      let self = this

      this.$axios.interceptors.response.use(function(response) {
        return response
      }, function(error) {
        if (error.response && error.response.status >= 400) {
          let requestData = {}

          try {
            requestData = JSON.parse(error.response.config.data)
            // eslint-disable-next-line no-empty
          } catch (e) {
          }

          if (!!requestData && !!requestData.domainType && requestData.domainType.toLowerCase() === 'soupe_ida.user_domain') {
            if (error.response.status === 401) {
              self.$cookie.delete('logged_in')
              window.location.reload()
            } else {
              let message = (!!error.response.data && !!error.response.data.message)
                ? error.response.data.message : self.$t('soupe.ui.fragments.messages.server_error')
              self.$error({
                message: message
              })
            }
          }
        }

        return Promise.reject(error)
      })
    },
    registerLoadingEventListener() {
      let self = this

      let num = 0

      this.$axios.interceptors.request.use(function(config) {
        num++
        self.$emit('loading')
        return config
      })

      this.$axios.interceptors.response.use(response => {
        num--
        if (num <= 0) {
          self.$emit('loaded')
        }

        return response
      }, error => {
        num--
        if (num <= 0) {
          self.$emit('loaded')
        }

        return Promise.reject(error)
      })

      this.$on('loading', function() {
        if (!this.loading) {
          this.loading = true
        }
      })

      this.$on('loaded', function() {
        self.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.loading {
  align-items: center;
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(9, 9, 9, .1);
  z-index: 100000
}

.soupe-ui-message-box {
  max-width: 600px;
  width: 400px;
  margin: 0px auto;
  top: 80px !important;
}

.soupe-ui-layout-container {
  min-width: 800px;
}

.soupe-ui-layout-body {
  min-height: 500px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
