<template>
  <empty-dashboard>
    <bricks-workspace-header></bricks-workspace-header>
  </empty-dashboard>
</template>

<script>
import EmptyDashboard from '../EmptyDashboard'
import BricksWorkspaceHeader from '../../fragments/WorkspaceHeader'

export default {
  components: {
    BricksWorkspaceHeader,
    EmptyDashboard
  }
}
</script>
