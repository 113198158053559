import Vue from 'vue'
import {sync} from 'vuex-router-sync'
import VueCookie from 'vue-cookie'

import SoupeComponents from '@cheeryworks/soupe-ui-components-vuejs/src/components'
import SoupeFragments from '@cheeryworks-private/soupe-ui-fragments/src/fragments'
import SoupeUtils from '@cheeryworks-private/soupe-ui-fragments/src/utils'
import store from '../../store'
import router from './router'
import i18n from './i18n'
import App from './App.vue'

Vue.use(SoupeComponents)
Vue.use(SoupeFragments)
Vue.use(SoupeUtils)
Vue.use(VueCookie)

sync(store, router)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  router,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
