<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  beforeCreate() {
    if (this.$cookie.get('locale')) {
      this.$i18n.locale = this.$cookie.get('locale').split('_')[0]
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/app';
</style>
