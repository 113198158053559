<template>
  <div v-if="$route.params.workspace">
    <div class="px-6 pt-5">
      <div class="columns is-vcentered">
        <div class="column is-narrow">
          <img class="workspace-avatar" :src="workspace_avatar"/>
        </div>
        <div class="column">
          <div class="is-size-4 has-text-weight-medium">CheeryWorks</div>
          <div class="columns" v-if="workspace_email">
            <div class="column is-narrow">
              <a :href="workspace_site">{{ workspace_site }}</a>
            </div>
            <div class="column">
              <a class="text-gray-dark" itemprop="email"
                :href="'mailto:' + workspace_email">{{ workspace_email }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tabs is-boxed py-4">
      <ul>
        <li class="ml-6" v-bind:class="{'is-active': $route.matched[0].name.includes('projects')}">
          <a :href="$route.params.workspace ? '/' + $route.params.workspace + '/project' : '/project'">
            <span class="icon is-small"><i class="fas fa-th-large" aria-hidden="true"></i></span>
            <span>{{ $t('titles.projects') }}</span>
          </a>
        </li>
        <li v-bind:class="{'is-active': $route.matched[0].name.includes('bricks')}">
          <a :href="$route.params.workspace ? '/' + $route.params.workspace + '/brick' : '/brick'">
            <span class="icon is-small"><i class="fas fa-cubes" aria-hidden="true"></i></span>
            <span>{{ $t('titles.bricks') }}</span>
          </a>
        </li>
        <li v-bind:class="{'is-active': $route.matched[0].name.includes('components')}">
          <a :href="$route.params.workspace ? '/' + $route.params.workspace + '/component' : '/component'">
            <span class="icon is-small"><i class="fas fa-cube" aria-hidden="true"></i></span>
            <span>{{ $t('titles.components') }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import md5 from 'md5'

export default {
  name: 'bricks-workspace-header',
  data() {
    return {
      workspace_avatar: null,
      workspace_email: null,
      workspace_site: null
    }
  },
  mounted() {
    let avatarHash

    let workspaceEmail = this.$cookie.get('workspace_email')

    if (workspaceEmail) {
      avatarHash = md5(workspaceEmail)
    } else if (this.$route.params.workspace) {
      avatarHash = md5(this.$route.params.workspace)
    }

    if (avatarHash) {
      this.workspace_avatar = 'https://www.gravatar.com/avatar/' + avatarHash + '?s=128&d=identicon'
    }

    if (this.$cookie.get('workspace_email')) {
      this.workspace_email = this.$cookie.get('workspace_email')
    }

    if (this.$cookie.get('workspace_site')) {
      this.workspace_site = this.$cookie.get('workspace_site')
    }
  }
}
</script>

<style lang="scss" scoped>
.workspace-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 4px solid $grey;
}
</style>
