<template>
  <default-layout>
    <div class="vertical-container">
      <login-form></login-form>
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from '../layouts/DefaultLayout.vue'

export default {
  components: {
    DefaultLayout
  }
}
</script>

<style lang="scss" scoped>
.vertical-container {
  width: 400px;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
}
</style>
