<template>
  <default-layout>
    <div class="hero hero-body py-6">
      <div class="hero-body hero" style="width: 600px; margin: 0px auto;">
        <user-form></user-form>
      </div>
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from '../../layouts/DefaultLayout.vue'

export default {
  components: {
    DefaultLayout
  }
}
</script>
